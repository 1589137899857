import { Auth } from "../APISDK";

export const registerUser = async (payload) => Auth.register(payload);

export const loginUser = async (data) => Auth.login(data);

export const forgotPassword = async (data) => Auth.forgotPassword(data);

export const resendAndResetEmail = async (data) => Auth.resendAndResetEmail(data);

export const setPassword = async (payload) => Auth.setPassword(payload);

export const getStates = async (payload) => Auth.getStates(payload);

export const getUserDetails = async (payload) => Auth.getUserDetails(payload);

export const getPlaidTokenDetails = async (payload) => Auth.getPlaidTokenDetails(payload);

export const getUserProfileDetails = async (payload) => Auth.getUserProfileDetails(payload);

export const transferACH = async (data) => Auth.transferACH(data);

export const getUserTaxAndShipmentDetails = async (data) => Auth.getUserTaxAndShipmentDetails(data);

export const sendMiscDetails = async (payload) => Auth.sendMiscDetails(payload);

export const verifyPhoneNumber = async (payload) => Auth.verifyPhoneNumber(payload);

export const resendOTP = async (payload) => Auth.resendOTP(payload);
