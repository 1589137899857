import React from "react";
import CheckMail from "../../components/cards/onboardingCard/checkMail";
import "./style.scss";
import { LiveChatWidget } from '@livechat/widget-react'

const checkEMail = () => {
  return (
    <div className="bg bg-img">
      <div className="mid-card card-4">
        <CheckMail />
      </div>
      {/* <LiveChatWidget
      license="14411064"
      visibility="minimized"
    /> */}
    </div>
  );
};

export default checkEMail;
