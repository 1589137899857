import React, { useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import {
  loginUser,
  registerUser,
  forgotPassword,
  setPassword,
  getStates,
  getUserDetails,
  resendAndResetEmail,
  transferACH,
  getUserTaxAndShipmentDetails,
  getUserProfileDetails,
  sendMiscDetails,
  verifyPhoneNumber,
  resendOTP,
  getPlaidTokenDetails
} from "../../../api/user";
import { types } from "./types";

export const sampleFunction = (data) => (dispatch) => {
  dispatch({
    type: types.SAMPLE_TYPE,
    darkModeStatus: data,
  });
};

export const login = (data) => async (dispatch) => {
  try {
    const response = await loginUser(data);
    if (!response || !response.success) {
      // throw new Error("Login user failed");
      return { error: true, errorData: response.message, responseData: null };
    } else {
      const { user } = response.data;
      const { tokens } = response.data;
      localStorage.setItem("token", tokens.refresh);
      localStorage.setItem("userId", user.userId);
      localStorage.setItem("refresh-token", tokens.refresh.token);
      localStorage.setItem("access-token", tokens.access.token);
      localStorage.setItem("categoryChosen", "");
      dispatch({
        type: types.LOGIN_USER,
        payload: user,
      });
      return { error: false, errorData: null, responseData: null };
    }
  } catch (error) {
    return { error: true, errorData: error, responseData: null };
  }
};

export const register = (data) => async (dispatch) => {
  try {
    const response = await registerUser(data);
    if (!response || response.success == false) {
      //toaster
      // throw new Error("Registration failed");
      return { error: true, errorData: response.message, responseData: null };
    } else {
      const { user, tokens } = response.data;
      localStorage.setItem("refresh-token", tokens.refresh.token);
      localStorage.setItem("access-token", tokens.access.token);
      dispatch({
        type: types.REGISTER_USER,
        payload: tokens,
        user,
      });
      return { error: false, errorData: null, responseData: null };
    }
  } catch (error) {
    return { error: true, errorData: error, responseData: null };
  }
};


export const verifyPhoneNumberHandler = (data) => async (dispatch) => {
  try {
    const response = await verifyPhoneNumber(data);
    if (!response || response.success == false) {
      //toaster
      // throw new Error("Registration failed");
      return { error: true, errorData: response.message, responseData: null };
    } else {
      return { error: false, errorData: null, responseData: response };
    }
  } catch (error) {
    return { error: true, errorData: error, responseData: null };
  }
};

export const resendOTPHandler = (data) => async (dispatch) => {
  try {
    const response = await resendOTP(data);
    if (!response || response.success == false) {
      //toaster
      // throw new Error("Registration failed");
      return { error: true, errorData: response.message, responseData: null };
    } else {
      return { error: false, errorData: null, responseData: response };
    }
  } catch (error) {
    return { error: true, errorData: error, responseData: null };
  }
};

export const getStatesHandler = () => async (dispatch) => {
  try {
    const response = await getStates();
    if (!response || response.success == false) {
      //toaster
      // throw new Error("Registration failed");
      return { error: true, errorData: response.message, responseData: null };
    } else {
      const { states } = response.data;

      return { error: false, errorData: null, responseData: states };
    }
  } catch (error) {
    return { error: true, errorData: error, responseData: null };
  }
};

export const setPasswordHandler = (data) => async (dispatch) => {
  try {
    const response = await setPassword(data);
    if (!response || !response.success) {
      return { error: true, errorData: response.message, responseData: null };
    } else {
      return { error: false, errorData: null, responseData: null };
    }
  } catch (error) {
    return { error: true, errorData: error, responseData: null };
  }
};

export const forgotPasswordHandler = (data) => async (dispatch) => {
  try {
    const response = await forgotPassword(data);
    if (!response || !response.success) {
      return { error: true, errorData: response.message, responseData: null };
    } else {
      return { error: false, errorData: null, responseData: null };
    }
  } catch (error) {
    return { error: true, errorData: error, responseData: null };
  }
};

export const resendAndResetEmailHandler = (data) => async (dispatch) => {
  try {
    const response = await resendAndResetEmail(data);
    if (!response || !response.success) {
      return { error: true, errorData: response.message, responseData: null };
    } else {
      return { error: false, errorData: null, responseData: null };
    }
  } catch (error) {
    return { error: true, errorData: error, responseData: null };
  }
};

export const setAssistant = (assistant) => async (dispatch) => {
  dispatch({
    type: types.SET_ASSISTANT,
    payload: assistant,
  });
};

export const getUserDetailsHandler = (data) => async (dispatch) => {
  try {
    const response = await getUserDetails(data);

    if (!response || !response.success) {
      // throw new Error("Login user failed");
      return { error: true, errorData: response.message, responseData: null };
    } else {
      const { userDetails } = response.data;

      
      if (userDetails.userDetails.shippingAddress.length > 0) {
        let tempShipping = userDetails.userDetails.shippingAddress.map((e) => ({
          ...e,
          display: false,
        }));
        tempShipping.find((e) => e.default === true).display = true;
        
        userDetails.userDetails.shippingAddress = tempShipping;
      }

      if (userDetails.userDetails.billingAddress.length > 0) {
        let tempBilling = userDetails.userDetails.billingAddress.map((e) => ({
          ...e,
          display: false,
        }));
        tempBilling.find((e) => e.default === true).display = true;
        userDetails.userDetails.billingAddress = tempBilling;
      }
      

      dispatch({
        type: types.USER_DETAILS,
        userDetails,
      });
      return { error: false, errorData: null, responseData: null };
    }
  } catch (error) {
    return { error: true, errorData: error, responseData: null };
  }
};

export const getPlaidTokenDetailsHandler = (data) => async (dispatch) => {
  try {
    const response = await getPlaidTokenDetails(data);

    if (!response || !response.success) {
      // throw new Error("Login user failed");
      return { error: true, errorData: response.message, responseData: null };
    } else {

      return { error: false, errorData: null, responseData: response.data };
    }
  } catch (error) {
    return { error: true, errorData: error, responseData: null };
  }
}

export const getUserProfileDetailsHandler = (data) => async (dispatch) => {
  try {
    const response = await getUserProfileDetails(data);
    if (!response || !response.success) {
      // throw new Error("Login user failed");
      return { error: true, errorData: response.message, responseData: null };
    } else {
      const { userDetails } = response.data;

      
      if (userDetails.userDetails.shippingAddress.length > 0) {
        let tempShipping = userDetails.userDetails.shippingAddress.map((e) => ({
          ...e,
          display: false,
        }));
        tempShipping.find((e) => e.default === true).display = true;
        
        userDetails.userDetails.shippingAddress = tempShipping;
      }

      if (userDetails.userDetails.billingAddress.length > 0) {
        let tempBilling = userDetails.userDetails.billingAddress.map((e) => ({
          ...e,
          display: false,
        }));
        tempBilling.find((e) => e.default === true).display = true;
        userDetails.userDetails.billingAddress = tempBilling;
      }
      

      dispatch({
        type: types.USER_DETAILS,
        userDetails,
      });
      return { error: false, errorData: null, responseData: userDetails };
    }
  } catch (error) {
    return { error: true, errorData: error, responseData: null };
  }
};

export const getUserHeaderDetailsHandler = (data) => async (dispatch) => {
  try {
    const response = await getUserProfileDetails(data);
    if (!response || !response.success) {
      // throw new Error("Login user failed");
      return { error: true, errorData: response.message, responseData: null };
    } else {
      const { userDetails } = response.data;

      
      return { error: false, errorData: null, responseData: userDetails };
    }
  } catch (error) {
    return { error: true, errorData: error, responseData: null };
  }
};


export const sendMiscDetailsHandler = (data) => async (dispatch) => {
  try {
    const response = await sendMiscDetails(data);
    if (!response || !response.success) {
      // throw new Error("Login user failed");
      return { error: true, errorData: response.message, responseData: null };
    } else {
      return { error: false, errorData: null, responseData: response.data };
    }
  } catch (error) {
    return { error: true, errorData: error, responseData: null };
  }
};

export const updateUserDetailsHandler = (userDetails) => async (dispatch) => {
  try {

    let {shippingAddress, billingAddress, user} = userDetails.userDetails

    if(shippingAddress && shippingAddress.length > 0 && billingAddress && billingAddress.length > 0 ){

      let currentShippingAddress = shippingAddress.find((e) => e.display === true)
      let currentBillingAddress = billingAddress.find((e) => e.display === true)

      const taxAndShipmentDetails = await getUserTaxAndShipmentDetails({
        shippingAddress : currentShippingAddress,
        billingAddress : currentBillingAddress,
        userId : user.userId
      });

      if (!taxAndShipmentDetails || !taxAndShipmentDetails.success || !taxAndShipmentDetails.data || !taxAndShipmentDetails.data.userDetails) {
        // throw new Error("Login user failed");
        return { error: true, errorData: taxAndShipmentDetails.message, responseData: null };
      }
     
      userDetails.userDetails.taxAndShipment = taxAndShipmentDetails.data.userDetails.taxAndShipment

      console.log()

    }

    

    dispatch({
      type: types.USER_DETAILS,
      userDetails,
    });
    return { error: false, errorData: null, responseData: null };
  } catch (error) {
    return { error: true, errorData: error, responseData: null };
  }
};

export const transferACHHandler = (userEmail) => async (dispatch) => {
  try {
    const response = await transferACH(userEmail);
    if (!response || !response.success) {
      return { error: true, errorData: response.message, responseData: null };
    } else {
      return { error: false, errorData: null, responseData: response };
    }
  } catch (error) {
    return { error: true, errorData: error, responseData: null };
  }
};

// export const setPasswordHandler = (data) => async (dispatch) => {
//   try {
//     const response = await setPassword(data);
//     if (!response || !response.success) {
//       return { error: true, errorData: response.message, responseData: null };
//     } else {
//       return { error: false, errorData: null, responseData: null };
//     }
//   } catch (error) {
//     return { error: true, errorData: error, responseData: null };
//   }
// };
