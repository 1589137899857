import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import Title from "../../../text/title";
import PText from "../../../text/pText";
import "./style.scss";
import AvBtn from "../../../button/avBtn";
import Lottie from "react-lottie-player";
import lottieJson from "../../../../../static/images/common/customer-form-lottie.json";
import { register } from "../../../../actions/user/action";
import { navigate } from "gatsby";

// const  = () => {
  const CheckMail = ({ register, data }) => {

    
  const [isMobile, setMobile] = useState(false);
  const [localstorageUserState, setLocalstorageUserState] = useState({});

  useEffect(() => {
    if (window.innerWidth < 576) setMobile(true);
  const handleWindowSizeChange = () => {
    window.innerWidth < 576 ? setMobile(true) : setMobile(false);
  };
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  useEffect(() => {
    const localstorageUser = JSON.parse(localStorage.getItem("registeringUser"));
    setLocalstorageUserState({...localstorageUser});

    if (!localstorageUser) {
      if (!localstorageUser || !localstorageUser.name) {
        navigate("/onboarding/user-details");
      } else if (!localstorageUser || !localstorageUser.state) {
        navigate("/onboarding/brand-location");
      } else if (!localstorageUser || !localstorageUser.age) {
        navigate("/onboarding/brand-age");
      }
    }

  }, []);


  useEffect(() => {
    
    let localOject = {email:localstorageUserState.email}
    
    // let email = localstorageUserState.email
    localStorage.setItem(
      "registeringUserEmail",
      JSON.stringify(localOject)
    );

    setTimeout(() => {
      localStorage.removeItem('registeringUser')
    }, 500);
  }, [localstorageUserState])
  

  const goToResendEmail = () =>{
    navigate("/onboarding/resend-email");
  }

  return (
    <div className="onboarding row check-mail">
      <div className="top">
      <img src="/images/common/plus_new_logo.svg" />
      </div>
      <div className="col-md-6 col-lg-6 col-sm-6 col-12 r-image">
      <div className="d-flex justify-content-center lottie">
            {!isMobile && (
              <Lottie
                loop
                animationData={lottieJson}
                play
                style={{ width: 200, height: 200, margin: "auto" }}
              />
            )}
            {isMobile && (
              <Lottie
                loop
                animationData={lottieJson}
                play
                style={{ width: 120, height: 120, margin: "auto" }}
              />
            )}
          </div>
      </div>
      <div className="col-md-6 col-lg-6 col-sm-6 col-12  step-content">
        <div className="brand-focus-heading">
          <Title text="Check your email!" />
        </div>
        {/* <div className="d-flex">
          <LinkText text="We've sent an email to" color="#004E93" />
          <LinkText text=" micky.mx@gmail.com" bold={true} color="#004E93" />
        </div> */}
        <p className="text-center-cm">
          <span className="txt-span">We've sent an email to </span>
          <span className="txt-span bold-span">{localstorageUserState && localstorageUserState.email && localstorageUserState.email} </span>
        </p>
        <div className="d-flex p-space justify-center text-center-cm">
          <div>
          Click the link in the email to confirm your address<br /> and activate your account.
          </div>
        </div>
        {/* <div className="mt-35 text-center-cm">
          <AvBtn
            tag="Confirm to activate"
            background="#FFE700"
            color="#004E93"
          />
        </div> */}
        <div className="d-flex p-space justify-center">
        <span className="txt-span bold-span">Didn't get the email?</span>
          
        </div>
        <div className="d-flex p-space-min justify-center">
        <span className="txt-span">Check your spam folder or &nbsp;</span> 
        <span className="click-here" onClick={goToResendEmail}>click here</span>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = ({ data }) => ({
  data: data,
});
export default connect(mapStateToProps, { register })(CheckMail);
